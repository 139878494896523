import React from "react";
import {Button} from "reactstrap";

const ApiErrorMessage = (props) => {
  const handleLogout = () => {
    window.location.replace('/sign-in');
  };

  return (
    <div className="d-flex flex-column">
      {props.message}
      <Button
        block
        outline
        onClick={handleLogout}
        className="my-3"
      >
        Sign Out
      </Button>
    </div>
  )
};

export default ApiErrorMessage;