import async from '../components/Async';

import {
  MessageCircle as MessageCircleIcon,
  PieChart as PieChartIcon,
  Home as HomeIcon,
  Sliders as SlidersIcon
} from 'react-feather';

// Report pages
import MarketView from '../pages/reports/components/MarketView';
import CompletedProjects from '../pages/reports/components/CompletedProjects';
import EmployeeDetails from '../pages/reports/components/EmployeeDetails';
import TimeAllocations from '../pages/reports/components/TimeAllocations';

// Facilitator Reports
import ScoreCardReport from '../pages/facilitatorReports/ScoreCardReport';
import EngagementGrowthReport from '../pages/facilitatorReports/EngagementGrowthReport';
import SkillGrowthReport from '../pages/facilitatorReports/SkillGrowthReport';
import TimeAllocationReport from '../pages/facilitatorReports/TimeAllocationReport';
import ConversationResponseReport from '../pages/facilitatorReports/ConversationResponseReport';

// Pages
const Dashboard = async(() => import('../pages/dashboard/Dashboard'));
const Conversations = async(() =>
  import('../pages/conversations/Conversations')
);
const Reports = async(() => import('../pages/reports/Reports'));

// Facilitator
const FacilitatorDashboard = async(() =>
  import('../pages/facilitator/FacilitatorDashboard')
);
const FacilitatorReports = async(() =>
  import('../pages/facilitatorReports/FacilitatorReports')
);

// Routes
const dashboardRoutes = {
  path: '/dashboard',
  name: 'Dashboard',
  icon: HomeIcon,
  component: Dashboard,
  containsHome: true,
  children: null,
};

const conversationsRoutes = {
  path: '/conversations/:channelId',
  to: '/conversations/general',
  name: 'Conversations',
  icon: MessageCircleIcon,
  component: Conversations,
  children: null,
};

export const reportsRoutes = {
  path: '/reports',
  name: 'Reports',
  icon: PieChartIcon,
  component: Reports,
  children: [
    {
      path: '/reports/market-view',
      name: 'Market View',
      component: MarketView,
      desc: 'View rating points for all teams across months.',
    },
    {
      path: '/reports/completed-projects',
      name: 'Completed Tasks',
      component: CompletedProjects,
      desc: 'Review your completed task information and feedback.',
    },
    {
      path: '/reports/employee-details',
      name: 'Employee Details',
      component: EmployeeDetails,
      desc: 'View progress of individual employees.',
    },
    {
      path: '/reports/time-allocation',
      name: 'Time Allocation',
      component: TimeAllocations,
      desc: 'View your cumulative token usage.',
    },
  ],
};

const facilitatorDashboardRoutes = {
  path: '/facilitator',
  name: 'Facilitator Dashboard',
  icon: SlidersIcon,
  component: FacilitatorDashboard,
  containsHome: true,
  children: null,
};

export const facilitatorReportRoutes = {
  path: '/facilitator/reports',
  name: 'Facilitator Reports',
  component: FacilitatorReports,
  icon: PieChartIcon,
  children: [
    {
      path: '/facilitator/reports/score-card',
      name: 'Team Score Card',
      component: ScoreCardReport,
      desc: 'View all points for all teams across months.',
    },
    {
      path: '/facilitator/reports/time-allocation',
      name: 'Time Allocation',
      component: TimeAllocationReport,
      desc: 'Where did all the time go?',
    },
    {
      path: '/facilitator/reports/engagement-growth',
      name: 'Engagement Growth',
      component: EngagementGrowthReport,
      desc: 'How has engagement changed over time.',
    },
    {
      path: '/facilitator/reports/skill-growth',
      name: 'Skill Growth',
      component: SkillGrowthReport,
      desc: 'How have skills grown over time.',
    },
    {
      path: '/facilitator/reports/conversation-responses',
      name: 'Conversation Responses',
      component: ConversationResponseReport,
      desc: 'Responses to conversations.',
    },
  ],
};

// Player specific routes
export const player = [dashboardRoutes, conversationsRoutes, reportsRoutes];

// Facilitator specific routes
export const facilitator = [
  facilitatorDashboardRoutes,
  facilitatorReportRoutes,
];

// All routes
export default [dashboardRoutes, conversationsRoutes, reportsRoutes];
