import { createStore, applyMiddleware, combineReducers } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import playerMiddleware from '../middleware/playerMiddleware';
import facilitatorMiddleware from '../middleware/facilitatorMiddleware';
import reducers from "../reducers/index";

import createSocketIoMiddleware from 'redux-socket.io';
import io from 'socket.io-client';

let socket = io(getBaseUrl(),
  {
    path: getBaseUrl().includes("3001")
      ? "/socket.io"
      : "/socket/socket.io"
  });

let socketIoMiddleware = createSocketIoMiddleware(socket, "SOCKET_");

const rootReducer = combineReducers({
  ...reducers.shared,
  ...(
    isFacilitatorRoute()
      ? reducers.facilitator
      : reducers.player
  )
});

export default composeWithDevTools(
  applyMiddleware(
    socketIoMiddleware,
    isFacilitatorRoute()
      ? facilitatorMiddleware
      : playerMiddleware
  )
)(createStore)(rootReducer);

function getBaseUrl() {
  const {host, protocol} = window.location;
  return host.includes("3000")
    ? `${protocol}//${host.replace("3000", "3001")}`
    : host.includes("8080") // Special case for oculus local app
      ? 'https://mc-beta.abilitie.com'
      : `${protocol}//${host}`;
}

function isFacilitatorRoute() {
  return window.location.href.indexOf('/facilitator') > -1
    || window.location.href.indexOf('/admin') > -1
    || window.location.href.indexOf('/roster') > -1;
}