import React, { useState, useEffect } from "react";
import queryString from 'query-string';
import coverFile from "../../assets/img/cover.jpg";
import logoMark from "../../assets/img/logo-mark.png";
import {Input, Button} from "reactstrap";
import moment from 'moment';

const SignIn = (props) => {
  const { handleSignIn } = props;

  useEffect(() => {
    const parsedQueryStr = queryString.parse(window.location.search);
    if (parsedQueryStr.playerName) {
      handleSignIn(parsedQueryStr.playerName);
    }
  }, [handleSignIn]);

  const [playerName, setPlayerName] = useState("");
  const [invalidLoginMessage, setInvalidLoginMessage] = useState('');

  const handleLogin = async () => {
    const message = await props.handleSignIn(playerName.trim());
    if (message) {
      setInvalidLoginMessage(message);
    }
  };

  return (
    <React.Fragment>
      <div className="loginWrapper">
        <div className="coverPic">
          <img
            alt="MC"
            src={coverFile}
            data-src={coverFile}
            data-src-retina={coverFile}
          />
          <div className="coverCaption">
            <div className="coverTagline">
              Real world practice without the real world consequences.
            </div>
            <div className="coverCopyright">
              v5.0.8 - All rights reserved. &copy; {`${moment().year()} `}
              <a
                href="http://www.abilitie.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                Abilitie Inc.
              </a>
            </div>
          </div>
        </div>
        <div className="loginContainer">
          <div className="loginForm">
            <div className="logoLink">
              <img src={logoMark} className="loginLogo" alt="MC Logo" />
              MANAGEMENT CHALLENGE
            </div>
            <div>
              <div className="formGroup">
                <label>Player Name</label>
                <div className="controls">
                  <Input
                    bsSize="lg"
                    type="text"
                    name="playerName"
                    placeholder="Enter your player name"
                    value={playerName}
                    onChange={e => setPlayerName(e.target.value)}
                  />
                </div>
              </div>
              <div style={{ color: 'red', height: '22px', fontSize: '16px' }}>
                {invalidLoginMessage && <span>{invalidLoginMessage}</span>}
              </div>
              <Button
                disabled={!playerName}
                type="success"
                className="loginButton mt-1"
                onClick={handleLogin}
              >
                Sign In
              </Button>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default SignIn;
