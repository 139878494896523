import React from 'react';
import { Provider } from 'react-redux';
import ReduxToastr from 'react-redux-toastr';

import store from './redux/store/index';
import Routes from './routes/Routes';

const App = (props) => {
  React.useEffect(() => {
    if (window.cookieStore) {
      window.cookieStore.getAll().then((cookies) => {
        cookies.forEach((c) => {
          if (c.domain === 'abilitie.com') {
            console.log('Clearing cross domain cookie:', c);
            window.cookieStore.delete(c);
          }
        });
      });
    }
  }, []);

  return (
    <Provider store={store}>
      <Routes />
      <ReduxToastr
        timeOut={5000}
        newestOnTop={true}
        position="top-right"
        transitionIn="fadeIn"
        transitionOut="fadeOut"
        progressBar
        closeOnToastrClick
        removeOnHover={false}
      />
    </Provider>
  );
};

export default App;
