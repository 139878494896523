import _ from 'lodash';

export function calculateEffectivePoints(points, engagement) {
  if (engagement < 20) {
    return points - 1;
  } else if (engagement >= 20 && engagement < 50) {
    return points;
  } else if (engagement >= 50 && engagement < 70) {
    return points + 1;
  } else {
    return points + 2;
  }
}

export function getCompanyScores(historicalCompanyScores, teamNames, totalMonths) {
  const scoresPerCompany = _.groupBy(historicalCompanyScores, 'companyName');

  return _(teamNames)
    .mapValues((filtered, teamName) => {
      const scores = scoresPerCompany[teamName]
      return _.range(0, totalMonths + 1).map(month => {
        if (month === 0) return 0;
        const monthlySum = _(scores)
          .filter(s => s.month <= month)
          .sumBy('score');
        return monthlySum ? monthlySum : 0;
      });
    })
    .value();
}

export function getEngagementScores(employees) {
  return _(employees)
    .groupBy('companyName')
    .mapValues(teamEmployees => {
      const currentEngagement = teamEmployees.map(
        employee => employee.stats.motivation.number
      );
      return Math.floor(_.mean(currentEngagement));
    })
    .value();
}

export function sortScores(scores, isArr = true) {
  return Object.keys(scores)
    .reduce((acc, teamName) => {
      const thisTeamArray = scores[teamName];
      const newTeamObj = {
        teamName,
        score: isArr ? thisTeamArray[thisTeamArray.length - 1] : thisTeamArray,
      };
      return [...acc, newTeamObj];
    }, [])
    .sort((a, b) => parseInt(b.score) - parseInt(a.score));
}

export function getScoreRank(allTeamsData, teamKey ) {
    const allTeams = Object.keys(allTeamsData).map((key) => {
        const thisArr = allTeamsData[key];
       return { team: key, score: thisArr[thisArr.length - 1] }
    });

    const allScoresRanked = _(allTeams)
        .map((team) => team.score)
        .uniq()
        .sortBy((val) => val) // Insane but default .sort() treats ints like strings.
        .reverse()
        .value();

    const numericRank = allScoresRanked.indexOf(allTeams.find((t) => t.team === teamKey).score) + 1;
    return numericRank === 1
        ? '1st'
        : numericRank === 2
            ? '2nd'
            : numericRank === 3
                ? '3rd'
                : numericRank + 'th';
}