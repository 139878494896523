import React from 'react';
import { connect } from 'react-redux';
import { Bar } from 'react-chartjs-2';
import { Col, Row } from 'reactstrap';
import _ from 'lodash';
import { getReportTokenType, groupTimeAllocationsData } from '../../utils';

function TimeAllocation(props) {
  const aggregateDataByTeam = _(props.facilitatorReports.timeAllocations)
    .filter(
      ({ teamName }) => !!props.facilitatorReports.filteredTeams[teamName]
    )
    .groupBy('teamName')
    .mapValues((tokens) =>
      tokens
        .map((token) => ({
          type: getReportTokenType(token),
          month: token.month,
        }))
        .reduce(
          groupTimeAllocationsData,
          // Hard-code the x-axis groupings so that they stay in order and use consistent colors across teams.
          [{type: 'Coaching', count: 0}, {type: 'Recognition', count: 0}, {type: 'Contributed', count: 0}]
        )
    )
    .value();

  const highestCount = _.reduce(aggregateDataByTeam, (highestCount, teamData) =>
      _.maxBy(teamData, 'count').count > highestCount
        ? _.maxBy(teamData, 'count').count
        : highestCount
    , 0);

  return (
    <Row className="mb-4 pb-3">
      {!props.facilitatorReports.timeAllocations.length ? (
        <h4>This data is not yet available.</h4>
      ) : (
        _.map(
          aggregateDataByTeam,
          (aggregateData, teamName) => (
              <TeamBarChart
                key={teamName}
                backgroundColors={props.reportBackgroundColors}
                borderColors={props.reportBorderColors}
                teamName={teamName}
                barLabels={['Coaching', 'Recognition', 'Contributed']}
                barData={_.map(aggregateData, 'count')}
                fixedYAxisMax={highestCount}
              />
            )
        )
      )}
    </Row>
  );
}

export default connect((state) => ({
  reportTeamColors: state.theme.reportTeamColors,
  facilitatorReports: state.facilitatorReports,
  totalMonths: state.facilitatorState.currentMonth,
  reportBorderColors: state.theme.reportBorderColors,
  reportBackgroundColors: state.theme.reportBackgroundColors,
}))(TimeAllocation);

function TeamBarChart({
  teamName,
  barLabels,
  barData,
  backgroundColors,
  borderColors,
  fixedYAxisMax
}) {
  const data = {
    labels: barLabels,
    datasets: [
      {
        label: '',
        data: barData,
        backgroundColor: backgroundColors,
        borderColor: borderColors,
        borderWidth: 1,
      },
    ],
  };

  const options = {
    legend: {
      display: false,
    },
    tooltips: {
      mode: 'index',
      intersect: false,
    },
    hover: {
      mode: 'nearest',
      intersect: true,
    },
    scales: {
      xAxes: [
        {
          categoryPercentage: 0.4,
          barPercentage: 0.4,
          barThickness: 40,
          ticks: {
            maxRotation: 90,
            minRotation: 45,
          },
        },
      ],
      yAxes: [
        {
          ticks: {
            max: fixedYAxisMax,
            beginAtZero: true,
            userCallback: (label) => {
              // when the floored value is the same as the value we have a whole number
              if (Math.floor(label) === label) {
                return label;
              }
            },
          },
        },
      ],
    },
  };

  return (
    <Col md={6} className="xl-mb-4 mb-2" style={{ minHeight: '250px' }}>
      <h4>{teamName}</h4>
      <Bar data={data} options={options} />
    </Col>
  );
}
