import React from 'react';
import { connect } from 'react-redux';
import { calcTotalScore } from '../redux/reducers/player/gameStateReducer';

import { Collapse, Nav, Navbar, NavItem, Button } from 'reactstrap';

import { Calendar, Clock, Flag } from 'react-feather';
import * as types from '../redux/redux-constants';

import styled from 'styled-components';
import Timer from './Timer';
import store from "../redux/store";
import {TOGGLE_TEMPORARY_TUTORIAL_ACTIVE} from "../redux/redux-constants";

const NavbarComponent = ({
  dispatch,
  currentMonth,
  totalScore,
  tutorialStepIndexTargetId,
  secondsRemaining,
  isTemporaryTutorialActive,
  materialsLink
}) => {
  const isTutorialTarget = tutorialStepIndexTargetId === 'navbar';

  const handleToggleTutorial = () => {
    store.dispatch({
      type: TOGGLE_TEMPORARY_TUTORIAL_ACTIVE,
    });
  };

  return (
    <StyledNavbar
      id="navbar"
      color="primary"
      light
      expand
      isTutorialTarget={isTutorialTarget}
    >
      <span
        className="sidebar-toggle d-flex mr-2 text-white"
        onClick={() => {
          dispatch({
            type: types.SIDEBAR_VISIBILITY_TOGGLE,
          });
        }}
      >
        <i className="hamburger align-self-center" />
      </span>

      <Collapse navbar>
        <Nav className="ml-auto" navbar>
          {secondsRemaining !== -1 && (
            <NavItem className="text-light-purple d-flex align-items-center justify-content-center mr-4">
              <Clock size={20} />
              <span className="ml-2 font-weight-bold text-lg text-white">
                <Timer serverSecondsRemaining={secondsRemaining} />
              </span>
            </NavItem>
          )}

          <NavItem className="text-light-purple d-flex align-items-center justify-content-center mr-4">
            <Calendar size={20} />
            <span className="ml-2 font-weight-bold text-lg text-white">
              Month {currentMonth} (Q
              {currentMonth && Math.ceil(currentMonth / 3)})
            </span>
          </NavItem>

          <NavItem className="text-light-purple d-flex align-items-center justify-content-center mr-4">
            <Flag size={20} />
            <span className="ml-1">Score</span>
            <span className="ml-2 font-weight-bold text-lg text-white">
              {totalScore} Pts
            </span>
          </NavItem>
          {
            materialsLink &&
            <NavItem className="mr-2 p-1">
              <div className="text-white">
                <Button color="light" outline href={materialsLink} target="_blank" rel="noopener noreferrer">
                  Participant Workbook
                </Button>
              </div>
            </NavItem>
          }
          <NavItem className="p-1">
            <div className="text-white">
              {
                !isTemporaryTutorialActive &&
                <Button color="light" outline onClick={handleToggleTutorial}>
                  Launch Tutorial
                </Button>
              }
            </div>
          </NavItem>
        </Nav>
      </Collapse>
    </StyledNavbar>
  );
};

export default connect(state => ({
  app: state.app,
  currentMonth: state.gameState.playerContext.currentMonth,
  totalScore: calcTotalScore(state.gameState),
  // notifications: state.notification.notifications,
  // isTutorialLaunched: state.player.tutorialStepIndex !== null,
  tutorialStepIndexTargetId:
    state.player.tutorialStepIndex !== null
      ? state.player.tutorialSteps[state.player.tutorialStepIndex].targetElemId
      : null,
  secondsRemaining: state.gameState.secondsRemaining,
  isTemporaryTutorialActive: state.player.isTemporaryTutorialActive,
  materialsLink: state.gameState.playerContext?.participantResources['Participant Materials']
}))(NavbarComponent);

const StyledNavbar = styled(({ isTutorialTarget, ...rest }) => (
  <Navbar {...rest} />
))`
  z-index: ${p => p.isTutorialTarget && '1001'};
`;
