import _ from 'lodash';
const employeeAvatarImages = require.context('./assets/img/avatars')

export function getBaseUrl() {
  const { host, protocol } = window.location;
  return `${protocol}//${host}`;
}

export function getBaseApiUrl() {
  // Note that this only replaces 3000 with 8080 for local dev
  return getBaseUrl().replace('3000', '8080') + '/api';
}

export function getBaseSocketUrl() {
  return getBaseUrl().replace('3000', '3001');
}

export function isFacilitatorReportsView() {
  return window.location.href.indexOf('facilitator/reports') > -1;
}

export function groupTimeAllocationsData(accumulator, token) {
  // TODO: I think this could be _.groupBy('type') and you count the array items.
  return _.some(accumulator, { type: token.type })
    ? accumulator.map(o =>
        o.type === token.type ? _.assign({}, o, { count: o.count + 1 }) : o
      )
    : [...accumulator, _.assign({}, token, { count: 1 })];
}

export function getReportTokenType(token) {
  if (token.tokenUse === 'Supported Project') {
    return 'Contributed';
  } else if (
    token.tokenUse.startsWith('Supported') &&
    token.tokenUse !== 'Supported Project'
  ) {
    return 'Support';
  } else if (token.tokenUse.startsWith('Coached')) {
    return 'Coaching';
  } else if (token.tokenUse.startsWith('Recognized')) {
    return 'Recognition';
  } else {
    console.error('Unhandled token: ', token);
  }
}

export function getReportTokenCategoryType(token) {
  if (token.tokenUse.startsWith('Recognized')) {
    return token.recognitionType;
  } else if (
    token.tokenUse.startsWith('Coached') ||
    token.tokenUse === 'Supported Project'
  ) {
    const prefix = token.tokenUseCode.split('_')[0];
    return prefix === 'social' ? 'communication' : 'functional';
  }

  return '';
}

export function getEmployeeImageFile(imgFileName) {
  return employeeAvatarImages(`./${imgFileName}`);
}