import * as types from '../../redux-constants';
import _ from 'lodash';

const initialState = {
  selectedSessionId: localStorage.getItem('facilitatorSessionId') || null,
  allSessionIds: [],
  currentMonth: null,
  isMonthActive: false,
  companies: [],
  activeConversationId: null,
  activeConversation: {},
  allConversations: [],
  loadDataFileName: null,
  secondsRemaining: -1,
  resignedEmployees: [],
  videos: [],
  participantMaterials: null,
};

export default function reducer(state = initialState, action) {
  // This is for development purposes. If used in production, these types of assertions should be in middleware.
  if (action.type === types.SET_FACILITATOR_STATE) {
    const differentKeys = _.difference(
      _.keys(action.payload),
      _.keys(initialState)
    );

    if (differentKeys.length) {
      throw new Error(`Unknown game state keys: ${differentKeys.join(' ')}`);
    }
  }

  switch (action.type) {
    case types.SET_FACILITATOR_STATE: {
      const facilitatorState = action.payload;

      return _.assign({}, facilitatorState, {
        // TODO: review. This seems wrong, however, I like null for initial state but passing null in getFacilitatorState java method results in key being omitted.
        activeConversationId: facilitatorState.activeConversationId || null,
        activeConversation: facilitatorState.activeConversation || null,
        allConversations: facilitatorState.allConversations || [],
      });
    }
    case types.SET_FACILITATOR_SESSION_ID: {
      return _.assign({}, state, {
        selectedSessionId: action.payload,
      });
    }

    case types.SET_FACILITATOR_SECONDS_REMAINING: {
      return _.assign({}, state, {
        secondsRemaining: action.payload,
      });
    }

    case types.SET_FACILITATOR_ALL_SESSION_IDS: {
      return _.assign({}, state, {
        allSessionIds: action.payload,
      });
    }

    case types.SET_FACILITATOR_TIMER_UPDATE:
      return _.assign({}, state, { secondsRemaining: action.payload });

    default:
      return state;
  }
}
