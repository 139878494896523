import _ from 'lodash';
import React from 'react';
import { Bar } from 'react-chartjs-2';
import { Badge, Col, Row } from 'reactstrap';
import { connect } from 'react-redux';
import styled from 'styled-components';
import {
  getReportTokenCategoryType,
  getReportTokenType,
  groupTimeAllocationsData,
} from '../../../utils';

function TimeAllocations(props) {
  const { timeAllocations } = props;

  // TODO: consider padding with data in the early months.
  const aggregateData = timeAllocations
    .map(token => ({
      type: getReportTokenType(token),
      month: token.month,
    }))
    .reduce(
      groupTimeAllocationsData,
      [{type: 'Coaching', count: 0}, {type: 'Recognition', count: 0}, {type: 'Contributed', count: 0}]
    );

  const data = {
    labels: ['Coaching', 'Recognition', 'Contributed'],
    datasets: [
      {
        label: '',
        data: _.map(aggregateData, 'count'),
        backgroundColor: props.reportBackgroundColors,
        borderColor: props.reportBorderColors,
        borderWidth: 1,
      },
    ],
  };

  const options = {
    legend: {
      display: false,
    },
    tooltips: {
      mode: 'index',
      intersect: false,
    },
    hover: {
      mode: 'nearest',
      intersect: true,
    },
    scales: {
      xAxes: [
        {
          categoryPercentage: 0.4,
          barPercentage: 0.4,
          barThickness: 40,
          ticks: {
            maxRotation: 90,
            minRotation: 45,
          },
        },
      ],
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
            userCallback: label => {
              // when the floored value is the same as the value we have a whole number
              if (Math.floor(label) === label) {
                return label;
              }
            },
          },
        },
      ],
    },
  };

  const timeAllocationsByMonth = _.groupBy(timeAllocations, 'month');

  const getTokenTypeColor = token =>
    props.reportBorderColors[
      _.findIndex(aggregateData, { type: getReportTokenType(token) })
    ];

  return (
    <Col>
      <Bar data={data} options={options} />
      {_.keys(timeAllocationsByMonth)
        .reverse()
        .map(month => (
          <div key={month}>
            <Row>
              <div>Month: {month}</div>
            </Row>
            {_.flatMap(
              _.chunk(timeAllocationsByMonth[month], 3),
              (chunk, chunkIndex) => (
                <Row className="my-2" key={chunkIndex}>
                  {chunk.map((token, tokenIndex) => (
                    <TimeAllocationPill
                      key={chunkIndex + '|' + tokenIndex}
                      colorOverride={getTokenTypeColor(token)}
                      pill
                    >
                      <span>{getReportTokenType(token)}</span>
                      {getReportTokenCategoryType(token) ? (
                        <span>
                          &nbsp;(
                          {getReportTokenCategoryType(token).toLowerCase()})
                        </span>
                      ) : null}
                    </TimeAllocationPill>
                  ))}
                </Row>
              )
            )}
          </div>
        ))}
    </Col>
  );
}

export default connect(state => ({
  timeAllocations: state.reports.timeAllocations,
  reportBorderColors: state.theme.reportBorderColors,
  reportBackgroundColors: state.theme.reportBackgroundColors,
  theme: state.theme.currentTheme,
}))(TimeAllocations);

const TimeAllocationPill = styled(({ colorOverride, ...rest }) => (
  <Badge {...rest} />
))`
  max-width: 170px;
  min-width: 170px;
  padding: 5px;
  margin-right: 15px;
  color: white;
  font-weight: 600;
  background-color: ${p => p.colorOverride};
`;
