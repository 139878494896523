import React from 'react';
import { Line } from 'react-chartjs-2';
import {
  Row,
  Col,
  // DropdownToggle,
  // DropdownMenu,
  // DropdownItem,
  // UncontrolledButtonDropdown,
  // FormGroup,
  // Label,
  // Input,
} from 'reactstrap';
import { connect } from 'react-redux';
import _ from 'lodash';

function generateMonths(total) {
  return _.range(0, total + 1).map(month => 'Month ' + month);
}
function EngagementGrowth(props) {
  // const [filteredEmployee, setFilteredEmployee] = useState(null);
  const employeeHistoryByTeam = _(props.facilitatorReports.employees)
    .groupBy('companyName')
    .mapValues(teamEmployees => {
      const summedEngagement = teamEmployees.reduce((acc, employee) => {
        // check is current month active using props.isMonthActive
        const motivationHistory =
          employee.stats.motivation.history.length > 0
            ? employee.stats.motivation.history.split('|')
            : [];
        const initialValue = [employee.stats.motivation.initialBase];
        const motivationalData =
          props.isMonthActive || props.totalMonths > motivationHistory.length
            ? initialValue.concat(
                motivationHistory.concat(employee.stats.motivation.number)
              )
            : initialValue.concat(motivationHistory);
        return motivationalData.map((m, i) =>
          acc[i] ? acc[i] + parseInt(m) : parseInt(m)
        );
      }, []);
      return summedEngagement.map(sum =>
        Math.floor(sum / teamEmployees.length)
      );
    })
    .value();
  const teamNames = Object.keys(employeeHistoryByTeam);
  const filteredTeams = teamNames.filter(
    x => !!props.facilitatorReports.filteredTeams[x]
  );
  //const employees = _.uniqBy(props.facilitatorReports.employees, 'firstName');

  const data = {
    labels: generateMonths(props.totalMonths),
    datasets: filteredTeams.map((teamName, i) => {
      return {
        label: teamName,
        fill: true,
        backgroundColor: 'transparent',
        borderColor: props.reportTeamColors[i],
        data: employeeHistoryByTeam[teamName],
      };
    }),
  };

  const options = {
    maintainAspectRatio: false,
    legend: {
      display: true,
    },
    tooltips: {
      intersect: false,
    },
    hover: {
      intersect: true,
    },
    plugins: {
      filler: {
        propagate: false,
      },
    },
    scales: {
      xAxes: [
        {
          reverse: true,
          gridLines: {
            color: 'rgba(0,0,0,0.05)',
          },
        },
      ],
      yAxes: [
        {
          ticks: {
            stepSize: 100,
          },
          display: true,
          borderDash: [5, 5],
          gridLines: {
            color: 'rgba(0,0,0,0)',
            fontColor: '#fff',
          },
        },
      ],
    },
  };

  return (
    <React.Fragment>
      <Row className="mb-4 pb-3">
        {props.totalMonths > 0 ? (
          <Col md={12} style={{ height: '300px' }}>
            {/*<UncontrolledButtonDropdown className="mb-2">*/}
            {/*  <DropdownToggle caret outline color="dark">*/}
            {/*    Filter by Employee*/}
            {/*  </DropdownToggle>*/}
            {/*  <DropdownMenu persist>*/}
            {/*    <DropdownItem header>*/}
            {/*      Get Report for Single Employee*/}
            {/*    </DropdownItem>*/}
            {/*    {employees.map(employee => {*/}
            {/*      return (*/}
            {/*        <div*/}
            {/*          key={employee.id}*/}
            {/*          className="dropdown-item"*/}
            {/*          onClick={e => setFilteredEmployee(employee.firstName)}*/}
            {/*        >*/}
            {/*          <FormGroup check>*/}
            {/*            <Label check>*/}
            {/*              <Input*/}
            {/*                type="checkbox"*/}
            {/*                checked={filteredEmployee === employee.firstName}*/}
            {/*                readOnly*/}
            {/*              />{' '}*/}
            {/*              {employee.firstName}*/}
            {/*            </Label>*/}
            {/*          </FormGroup>*/}
            {/*        </div>*/}
            {/*      );*/}
            {/*    })}*/}
            {/*    <div*/}
            {/*      className={'dropdown-item'}*/}
            {/*      onClick={e => setFilteredEmployee(null)}*/}
            {/*    >*/}
            {/*      <FormGroup check>*/}
            {/*        <Label check>*/}
            {/*          <Input*/}
            {/*            type="checkbox"*/}
            {/*            checked={filteredEmployee === null}*/}
            {/*            readOnly*/}
            {/*          />{' '}*/}
            {/*          No Filter*/}
            {/*        </Label>*/}
            {/*      </FormGroup>*/}
            {/*    </div>*/}
            {/*  </DropdownMenu>*/}
            {/*</UncontrolledButtonDropdown>*/}
            <h4>Average Engagement Change By Team</h4>
            <Line data={data} options={options} />
          </Col>
        ) : (
          <h4>This data is not yet available.</h4>
        )}
      </Row>
    </React.Fragment>
  );
}

export default connect(state => ({
  isMonthActive: state.facilitatorState.isMonthActive,
  reportTeamColors: state.theme.reportTeamColors,
  facilitatorReports: state.facilitatorReports,
  totalMonths: state.facilitatorState.currentMonth,
}))(EngagementGrowth);
