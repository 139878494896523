import _ from 'lodash';
import {
  deleteTutorialSession,
  getConversationState,
  getGameState,
  getReportState,
} from '../../services/apiService';
import { toastr } from 'react-redux-toastr';
import * as types from '../redux-constants';
import { getPlayer, getSessionId, handleSignOut } from '../../services/authService';

// TODO: POC. If we use this approach, decide best way to breakout into seperate constant files, type prefix, and potentially different middleware/reducers.
const SOCKET_ACTION_TYPES = [
  types.RULES_APPLIED,
  types.TRAINING_RESULT,
  types.GET_GAME_STATE,
  types.GET_CONVERSATION_STATE,
];

export default store => next => action => {
  const { type, payload, companyName, playerName, sessionId } = action;

  if (
    type === types.CLOSE_TUTORIAL_SESSIONS &&
    sessionId === getSessionId() &&
    store.getState().player.tutorialStepIndex !== null
  ) {
    next({
      type: types.END_TUTORIAL,
    });
    return;
  }

  // If the actions aren't intended for this company, player, or session then don't run the middleware
  const thisPlayerName = store.getState().gameState.playerContext.playerName;
  if (
    _.includes(SOCKET_ACTION_TYPES, type) &&
    (!actionMatchesPlayerSessionId(store.getState(), sessionId) ||
      (companyName && !actionsMatchCompany(store.getState(), companyName)) ||
      (playerName && playerName !== thisPlayerName))
  ) {
    return;
  }

  switch (type) {
    case types.AUTHENTICATION_ERROR: {
      handleSignOut();
      window.location.reload();
      return;
    }
    case types.RULES_APPLIED: {
      _.reject(
        payload,
        r =>
          (r.actionType === 'COACH' || r.actionType === 'RECOGNIZE') &&
          r.playerName !== getPlayer()
      ).forEach(
        rule =>
          rule.responseText &&
          toastr.light(rule.employee_name + ' Says', rule.responseText, {
            status: 'info',
            icon: 'info',
            timeOut: 60000,
            position: 'bottom-right',
            //removeOnHoverTimeOut: 60000,
            removeOnHover: false
          })
      );
      return;
    }

    case types.TRAINING_RESULT: {
      // TODO: we may want a better way to handle targeting specfic players within in team instead of needing these specific checks.
      const trainingStatus = store.getState().player.trainingStatus;
      // The trainingStatus state should always be there if this client initiated the training. The existence check is only
      // for weird cases like having two tabs open or refreshing the browser before action completes.
      if (
        !!trainingStatus &&
        payload.employeeId === trainingStatus.employeeId
      ) {
        if (payload.error) {
          // Clear the training state if there was an error and let generic API handler show the message to player.
          console.error('Training error:', payload.error);
          next({ type: types.END_TRAINING_EMPLOYEE });
          return;
        }

        next({
          type: types.SET_TRAINING_RESULT_POINTS,
          payload: payload.points,
        });

        setTimeout(() => next({ type: types.END_TRAINING_EMPLOYEE }), 5000);
      }

      return;
    }

    case types.ROLLBACK_ROUND_COMPLETE:
    case types.GET_GAME_STATE: {
      getGameState();
      if (_.includes(window.location.pathname, '/reports')) {
        getReportState();
      }
      return;
    }

    case types.GET_CONVERSATION_STATE: {
      getConversationState();
      return;
    }

    case types.SET_GAME_STATE: {
      next(action);
      return;
    }

    case types.END_TUTORIAL: {
      const tutorialStepsLength = store.getState().player.tutorialSteps.length;
      const cachedTutorialIndex = store.getState().player.tutorialStepIndex;

      if (cachedTutorialIndex === tutorialStepsLength - 1) {
        deleteTutorialSession();
      }

      next(action);
      return;
    }

    default:
      next(action);
  }
};

function actionsMatchCompany(state, targetCompanyName) {
  if (
    !state ||
    !state.gameState ||
    !state.gameState.playerContext ||
    !targetCompanyName
  ) {
    return false;
  }

  return (
    targetCompanyName === 'all' ||
    state.gameState.playerContext.companyName === targetCompanyName
  );
}

function actionMatchesPlayerSessionId(state, targetSessionId) {
  // TODO: use get active session selector
  const playerSessionId = state
    ? state.player.tutorialStepIndex === null
      ? getSessionId()
      : getSessionId() + '-' + getPlayer() + '-tutorial'
    : null;

  return targetSessionId === playerSessionId;
}
