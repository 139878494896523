import React from 'react';
import { Container, Row, Col } from 'reactstrap';

const Footer = () => (
  <footer className="footer">
    <Container fluid>
      <Row className="text-muted">
        <Col xs="6" className="text-left">
          <ul className="list-inline">
            <li className="list-inline-item border-right pr-3 mr-3">
              <a
                href="https://portal.abilitie.com/help"
                target="_blank"
                rel="noopener noreferrer"
              >
                Support
              </a>
            </li>
            <li className="list-inline-item border-right pr-3 mr-3">
              <a
                href="https://www.abilitie.com/privacy"
                target="_blank"
                rel="noopener noreferrer"
              >
                Privacy
              </a>
            </li>
            <li className="list-inline-item">
              <a
                href="https://www.abilitie.com/privacy"
                target="_blank"
                rel="noopener noreferrer"
              >
                Terms of Service
              </a>
            </li>
          </ul>
        </Col>
        <Col xs="6" className="text-right">
          <p className="mb-0">
            &copy; {new Date().getFullYear()} -{' '}
            <span className="text-muted">
              All Rights Reserved.{' '}
              <a
                className="text-warning"
                href="https://www.abilitie.com"
                rel="noopener noreferrer"
                target="_blank"
              >
                Abilitie
              </a>
            </span>
          </p>
        </Col>
      </Row>
    </Container>
  </footer>
);

export default Footer;
