import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { NavLink, withRouter } from 'react-router-dom';
import styled from 'styled-components';

import { Row, Col, Progress, Badge, Collapse, Button } from 'reactstrap';
import PerfectScrollbar from 'react-perfect-scrollbar';

import { User, Clock } from 'react-feather';
import routes from '../routes/index';
import logo from '../assets/img/logo.png';
import logoMark from '../assets/img/logo-mark.png';
import _ from 'lodash';

const SidebarCategory = withRouter(
  ({
    name,
    badgeColor,
    badgeText,
    icon: Icon,
    isOpen,
    children,
    onClick,
    location,
    sidebarIsOpen,
    path,
  }) => {
    const getSidebarItemClass = (path) => {
      return location.pathname.indexOf(path) !== -1 ||
        _.includes(location.pathname, path.replace('*', ''))
        ? 'active'
        : '';
    };

    return (
      <li className={'sidebar-item ' + getSidebarItemClass(path)}>
        <span
          data-toggle={sidebarIsOpen && 'collapse'}
          className={'sidebar-link ' + (!isOpen ? 'collapsed' : '')}
          onClick={onClick}
          aria-expanded={isOpen ? 'true' : 'false'}
        >
          {sidebarIsOpen && Icon ? (
            <Icon size={18} className="align-middle mr-3" />
          ) : null}
          {!sidebarIsOpen && Icon ? (
            <Icon size={24} className="align-middle ml-1" />
          ) : null}
          {sidebarIsOpen && <span className="align-middle">{name}</span>}
          {badgeColor && badgeText ? (
            <Badge pill color={badgeColor} size={18} className="sidebar-badge">
              {badgeText}
            </Badge>
          ) : null}
        </span>
        <Collapse isOpen={isOpen}>
          <ul id="item" className={'sidebar-dropdown list-unstyled'}>
            {children}
          </ul>
        </Collapse>
      </li>
    );
  }
);

const SidebarItem = withRouter(
  ({
    name,
    badgeColor,
    badgeText,
    icon: Icon,
    location,
    path,
    to,
    tutorialStepIndexTargetId,
    sidebarIsOpen,
  }) => {
    const getSidebarItemClass = () => {
      const includesRouteParam = _.includes(path, ':');
      return location.pathname === path ||
        (includesRouteParam &&
          _.includes(location.pathname, path.split(':')[0]))
        ? 'active'
        : '';
    };

    const id = _.camelCase(name);

    return (
      <StyledNavLinkWrapper
        className={'sidebar-item ' + getSidebarItemClass(path)}
        id={id}
        isTutorialTarget={id === tutorialStepIndexTargetId}
      >
        <NavLink to={to} className="sidebar-link" activeClassName="active">
          {sidebarIsOpen && Icon ? <Icon size={18} className="mr-3" /> : null}
          {!sidebarIsOpen && Icon ? <Icon size={24} className="ml-1" /> : null}
          {sidebarIsOpen && name}
          {badgeColor && badgeText ? (
            <Badge color={badgeColor} size={18} className="sidebar-badge">
              {badgeText}
            </Badge>
          ) : null}
        </NavLink>
      </StyledNavLinkWrapper>
    );
  }
);

function Sidebar(props) {
  const [routeStatus, setRouteStatus] = useState({});
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    if (!initialized) {
      const pathName = props.location.pathname;

      routes.forEach((route, index) => {
        const isActive =
          pathName.indexOf(route.path) === 0 ||
          _.includes(pathName, route.path.replace('*', ''));
        const isOpen = route.open;
        const isHome = route.containsHome && pathName === '/';

        setRouteStatus((routeStatus) =>
          _.assign({}, routeStatus, { [index]: isActive || isOpen || isHome })
        );
      });

      setInitialized(true);
    }
  }, [setRouteStatus, initialized, props.location.pathname]);

  const toggle = (index) => {
    setRouteStatus(_.assign({}, routeStatus, { [index]: !routeStatus[index] }));
  };

  const {
    sidebar,
    messages,
    playerContext,
    tokens,
    tutorialStepIndexTargetId,
  } = props;
  const isSidebarOpen = sidebar.isOpen;
  const unreadCount = messages
    ? _.filter(messages, { hasBeenRead: false }).length
    : 0;

  const sidebarRoutes = routes;

  return (
    <div
      className={
        'sidebar' +
        (!isSidebarOpen ? ' toggled' : '') +
        (sidebar.isSticky ? ' sidebar-sticky' : '')
      }
    >
      <div className="sidebar-content">
        <PerfectScrollbar>
          {!isSidebarOpen && (
            <a className="sidebar-brand-mark" href="/">
              <img src={logoMark} alt="Management Challenge" width="50px" />
            </a>
          )}
          {isSidebarOpen && (
            <a className="sidebar-brand" href="/">
              <img src={logo} alt="Management Challenge" width="100%" />
            </a>
          )}

          <ul className="sidebar-nav mt-3">
            {sidebarRoutes.map((category, index) => {
              return (
                <React.Fragment key={index}>
                  {category.header ? (
                    <li className="sidebar-header">{category.header}</li>
                  ) : null}

                  {category.children && isSidebarOpen ? (
                    <SidebarCategory
                      name={category.name}
                      badgeColor={category.badgeColor}
                      badgeText={category.badgeText}
                      icon={category.icon}
                      path={category.path}
                      to={category.to || category.path}
                      isOpen={routeStatus[index]}
                      onClick={() => toggle(index)}
                      sidebarIsOpen={isSidebarOpen}
                    >
                      {category.children.map((route, index) => (
                        <SidebarItem
                          key={index}
                          name={route.name}
                          to={route.to || route.path}
                          path={route.path}
                          badgeColor={route.badgeColor}
                          badgeText={route.badgeText}
                          desc={route.desc}
                          sidebarIsOpen={isSidebarOpen}
                        />
                      ))}
                    </SidebarCategory>
                  ) : (
                    <SidebarItem
                      name={category.name}
                      to={category.to || category.path}
                      path={category.path}
                      icon={category.icon}
                      tutorialStepIndexTargetId={tutorialStepIndexTargetId}
                      badgeColor={
                        category.name === 'Conversations'
                          ? 'danger'
                          : category.badgeColor
                      }
                      badgeText={
                        category.name === 'Conversations'
                          ? unreadCount
                          : category.badgeText
                      }
                      sidebarIsOpen={isSidebarOpen}
                    />
                  )}
                </React.Fragment>
              );
            })}
          </ul>
          <div className="px-3 py-5 bg-light">
            <div className="d-flex align-items-center justify-content-between">
              <h4 className={`mt-2 text-dark ${!isSidebarOpen && 'd-none'}`}>
                {props.isTeammateView
                  ? "Teammate's Time"
                  : 'My Time Allocation'}
              </h4>
              <Clock
                className={!isSidebarOpen ? 'ml-2' : ''}
                size={isSidebarOpen ? 20 : 24}
              />
            </div>
            <Row className="mt-3 mb-4 mx-0">
              <Col
                sm={isSidebarOpen ? 4 : 12}
                className={isSidebarOpen ? 'w-100 px-1' : 'mb-2 px-0'}
              >
                <Progress
                  className="progress-md"
                  color="success"
                  value={
                    tokens.length &&
                    tokens.filter((t) => !!t.usageDescription).length < 3
                      ? 1
                      : 0
                  }
                  max={1}
                />
              </Col>
              <Col
                sm={isSidebarOpen ? 4 : 12}
                className={isSidebarOpen ? 'w-100 px-1' : 'mb-2 px-0'}
              >
                <Progress
                  className="progress-md"
                  color="success"
                  value={
                    tokens.length &&
                    tokens.filter((t) => !!t.usageDescription).length < 2
                      ? 1
                      : 0
                  }
                  max={1}
                />
              </Col>
              <Col
                sm={isSidebarOpen ? 4 : 12}
                className={isSidebarOpen ? 'w-100 px-1' : 'mb-2 px-0'}
              >
                <Progress
                  className="progress-md"
                  color="success"
                  value={
                    tokens.length &&
                    tokens.filter((t) => !!t.usageDescription).length < 1
                      ? 1
                      : 0
                  }
                  max={1}
                />
              </Col>
            </Row>
            <div id="tokenContainer" style={{ zIndex: 1001 }}>
              {[0, 1, 2].map((index) =>
                tokens[index] &&
                tokens[index].usageDescription &&
                tokens[index].usageDescription.length ? (
                  <div
                    key={index}
                    className="bg-white border mt-2 px-2 p-1 d-flex align-items-center"
                  >
                    <Clock size={14} />
                    {isSidebarOpen ? (
                      tokens[index].usageDescription.length === 2 ? (
                        <div className="ml-2 d-flex flex-fill justify-content-between align-items-center">
                          <span className="text-secondary mr-2">
                            {tokens[index].usageDescription[0] === 'Supported'
                              ? 'Contributed'
                              : tokens[index].usageDescription[0]}
                          </span>
                          <span className="text-dark">
                            {tokens[index].usageDescription[1]}
                          </span>
                        </div>
                      ) : (
                        <span>{tokens[index].usageDescription.join(' ')}</span>
                      )
                    ) : (
                      <div className="ml-1 d-flex flex-fill justify-content-between align-items-center">
                        <span className="text-secondary mr-2">
                          {tokens[index].usageDescription[0][0]}{' '}
                          {tokens[index].usageDescription[1][0]}
                        </span>
                      </div>
                    )}
                  </div>
                ) : (
                  <div key={index} className="bg-light-gray mt-3 p-1">
                    &nbsp;
                  </div>
                )
              )}
            </div>
          </div>

          <div className="sidebar-bottom d-none d-lg-block">
            <div className="media">
              <div className="d-flex flex-column align-items-center">
                <User
                  size={36}
                  className="mr-4 text-muted"
                  style={{ marginLeft: 13 }}
                />
                <Button
                  onClick={() => props.history.push('/sign-in')}
                  outline
                  color="primary"
                  size="sm"
                  className="position-absolute d-flex align-items-center"
                  style={{
                    bottom: '13px',
                    left: '12px',
                    fontSize: '11px',
                    padding: '2px 4px 2px 4px',
                    lineHeight: '1',
                  }}
                >
                  LOGOUT
                </Button>
              </div>
              <div className="media-body">
                <div className="d-flex flex-column">
                  <h4 className="mb-0">
                    {playerContext && playerContext.playerName}
                  </h4>
                  <div className="text-sm text-muted">
                    {playerContext.companyName} |{' '}
                    {_.startCase(playerContext.playerRoleType)}
                  </div>
                </div>
                <div className="d-flex justify-content-between flex-fill">
                  <span>
                    <span className="text-muted">Session </span>
                    {playerContext.sessionId}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </PerfectScrollbar>
      </div>
    </div>
  );
}

export default withRouter(
  connect((store) => ({
    sidebar: store.sidebar,
    messages: store.conversations.messages,
    tokens:
      store.player.isTeammateView && store.gameState.canManagersCollaborate
        ? store.gameState.teammateTokens
        : store.gameState.tokens,
    tutorialStepIndexTargetId:
      store.player.tutorialStepIndex !== null
        ? store.player.tutorialSteps[store.player.tutorialStepIndex]
            .targetElemId
        : null,
    isTeammateView:
      store.gameState.canManagersCollaborate && store.player.isTeammateView,
  }))(Sidebar)
);

const StyledNavLinkWrapper = styled(({ isTutorialTarget, ...rest }) => (
  <li {...rest} />
))`
  z-index: ${(p) => (p.isTutorialTarget ? '1001' : 'unset')};
  position: ${(p) => (p.isTutorialTarget ? 'relative' : 'unset')};
`;
