import React, { useState } from 'react';
import {
  createSession,
  getFacilitatorState,
  getFacilitatorReportState,
  resetSession,
  rollbackRound,
} from '../services/facilitatorApiService';
import Navbar from './Navbar';
import {
  Button,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
  Nav,
  NavItem,
} from 'reactstrap';
import _ from 'lodash';
import { RefreshCcw, Rewind } from 'react-feather';

import store from '../redux/store';
import { SET_FACILITATOR_SESSION_ID } from '../redux/redux-constants';
import PromptModal from './PromptModal';

const FacilitatorNavbar = ({
  currentMonth,
  isMonthActive,
  handleToggleSidebar,
  allSessionIds,
  selectedSessionId,
}) => {
  const [sessionInputValue, setSessionInputValue] = useState('');
  const [isSessionMenuOpen, toggleSessionMenuOpen] = useState(false);
  const [showConfirmRollback, setShowConfirmRollback] = useState(false);
  const [showConfirmReset, setShowConfirmReset] = useState(false);

  const handleChangeNewSessionId = e => {
    setSessionInputValue(e.target.value);
  };

  const handleCreateSession = () => {
    createSession(sessionInputValue);
    setSessionInputValue('');
    toggleSessionMenuOpen(false);
  };

  const handleResetSession = () => {
      resetSession();
  };

  const handleSelectSession = sessionId => {
    store.dispatch({
      type: SET_FACILITATOR_SESSION_ID,
      payload: sessionId,
    });

    getFacilitatorState(sessionId);
    getFacilitatorReportState(sessionId);
  };

  const handleRollbackRound = () => {
    rollbackRound();
  };

  return (
    <Navbar color="dark" onToggleSidebar={handleToggleSidebar}>
      {showConfirmRollback && (
        <PromptModal
          titleLabel="Rollback Round"
          bodyLabel={
            <div>
              <p>
                Warning: this will impact all sessions!
              </p>
              <p>
                {
                  isMonthActive
                    ? `Rollback to start of\nmonth ${currentMonth}?`
                    : `Reopen\nmonth ${currentMonth}?`
                }
              </p>
            </div>
          }
          actionButtonLabel="OK"
          onClickDismissButton={() => setShowConfirmRollback(false)}
          onClickActionButton={() => {
            setShowConfirmRollback(false);
            handleRollbackRound();
          }}
        />
      )}
      {showConfirmReset && (
          <PromptModal
            titleLabel="Reset Session"
            bodyLabel={
              <div>
                <p>
                  Warning: This action will reset the simulation to Month 1 and <strong>erase all competition data.</strong>
                </p>
                <p>
                </p>
              </div>
            }
            confirmationText={"RESET"}
            actionButtonLabel="RESET"
            onClickDismissButton={() => setShowConfirmReset(false)}
            onClickActionButton={() => {
              setShowConfirmReset(false);
              handleResetSession();
            }}
        />
      )}
      <Nav className="ml-auto align-items-center" navbar>
        <NavItem style={{ width: '300px' }}>
          <Dropdown
            isOpen={isSessionMenuOpen}
            toggle={() =>
              !sessionInputValue && toggleSessionMenuOpen(!isSessionMenuOpen)
            }
          >
            <DropdownToggle caret color="light" className="w-100">
              {selectedSessionId ? (
                <span className="text-muted mr-4">
                  Current Session:{' '}
                  <span className="text-info">{selectedSessionId}</span>
                </span>
              ) : (
                'Select Session'
              )}
            </DropdownToggle>
            <DropdownMenu className="w-100">
              {selectedSessionId && (
                <React.Fragment>
                  <DropdownItem header>
                    Current Session:{' '}
                    <span className="text-info">{selectedSessionId}</span>
                  </DropdownItem>
                  <DropdownItem divider />
                </React.Fragment>
              )}
              {((!selectedSessionId && allSessionIds.length > 0) || (selectedSessionId && allSessionIds.length > 1)) && (
                <React.Fragment>
                  <DropdownItem header>Switch Session</DropdownItem>
                  {_.reject(
                    allSessionIds,
                    sId => sId === selectedSessionId
                  ).map(sId => (
                    <DropdownItem
                      key={sId}
                      onClick={() => handleSelectSession(sId)}
                    >
                      {sId}
                    </DropdownItem>
                  ))}
                  <DropdownItem divider />
                </React.Fragment>
              )}
              <DropdownItem header className="m-3 p-3 border bg-light">
                Create New Session
                <Input
                  className="my-3"
                  onChange={handleChangeNewSessionId}
                  placeholder="Session Name"
                  value={sessionInputValue}
                />
                <Button
                  color="info"
                  className="mr-2"
                  onClick={handleCreateSession}
                >
                  Submit
                </Button>
                <Button
                  color="dark"
                  outline
                  onClick={() =>
                    toggleSessionMenuOpen(false) || setSessionInputValue('')
                  }
                >
                  Cancel
                </Button>
              </DropdownItem>
            </DropdownMenu>
          </Dropdown>
        </NavItem>
        {!!selectedSessionId && (
          <React.Fragment>
            <NavItem className="ml-2">
              <Button
                color="light"
                outline
                onClick={()=>setShowConfirmReset(true)}
                className="d-flex flex-row align-items-center justify-content-center"
                style={{ minWidth: '100px' }}
              >
                <RefreshCcw size="14" className="mr-1">
                  Reset
                </RefreshCcw>
                <span>Reset</span>
              </Button>
            </NavItem>
            {
              selectedSessionId.toLowerCase() === 'main' &&
              <NavItem className="ml-2">
                <Button
                  disabled={currentMonth === 0}
                  color="light"
                  outline
                  onClick={() => {
                    setShowConfirmRollback(true);
                  }}
                  className="d-flex flex-row align-items-center justify-content-center"
                  style={{ minWidth: '100px' }}
                >
                  <Rewind size="14" className="mr-1" />
                  <div>Rollback</div>
                </Button>
              </NavItem>
            }
          </React.Fragment>
        )}
      </Nav>
    </Navbar>
  );
};

export default FacilitatorNavbar;
