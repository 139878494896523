import React from 'react';
import { Line } from 'react-chartjs-2';
import { Table, Row, Col } from 'reactstrap';
import { connect } from 'react-redux';
import _ from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from '@fortawesome/free-solid-svg-icons';
import { calculateEffectivePoints, getScoreRank } from '../../helpers';
function generateMonths(total) {
  return _.range(0, total + 1).map(month => 'Month ' + month);
}

function SkillGrowth(props) {
  const totalMonths = props.totalMonths;
  const employeeFunctionalByTeam = _(props.facilitatorReports.employees)
    .groupBy('companyName')
    .mapValues(teamEmployees => {
      return teamEmployees.reduce((acc, employee) => {
        const functionalHistory =
          employee.stats.functional.history.length > 0
            ? employee.stats.functional.history.split('|')
            : [];
        const motivationalHistory =
          employee.stats.motivation.history.length > 0
            ? employee.stats.motivation.history.split('|')
            : [];

        const effectiveFunctionalHistory = functionalHistory.map((val, i) => {
          return calculateEffectivePoints(
            parseInt(val) + 1,
            motivationalHistory[i]
          );
        });
        const initialValue = [
          calculateEffectivePoints(
            employee.stats.functional.initialBase,
            employee.stats.motivation.initialBase
          ),
        ];

        const functionalData =
          props.isMonthActive || totalMonths > effectiveFunctionalHistory.length
            ? initialValue.concat(
                effectiveFunctionalHistory.concat(
                  calculateEffectivePoints(
                    employee.stats.functional.number,
                    employee.stats.motivation.number
                  )
                )
              )
            : initialValue.concat(effectiveFunctionalHistory);
        return functionalData.map((m, i) =>
          acc[i] ? acc[i] + parseInt(m) : parseInt(m)
        );
      }, []);
    })
    .value();
  const teamNames = Object.keys(employeeFunctionalByTeam);

  const employeeCommunicationalByTeam = _(props.facilitatorReports.employees)
    .groupBy('companyName')
    .mapValues(teamEmployees => {
      return teamEmployees.reduce((acc, employee) => {
        const communicationalHistory =
          employee.stats.interpersonal.history.length > 0
            ? employee.stats.interpersonal.history.split('|')
            : [];
        const motivationalHistory =
          employee.stats.motivation.history.length > 0
            ? employee.stats.motivation.history.split('|')
            : [];

        const effectiveCommunicationalHistory = communicationalHistory.map(
          (val, i) => {
            return calculateEffectivePoints(
              parseInt(val) + 1,
              motivationalHistory[i]
            );
          }
        );
        const initialValue = [
          calculateEffectivePoints(
            employee.stats.interpersonal.initialBase,
            employee.stats.motivation.initialBase
          ),
        ];
        const communicationalData =
          props.isMonthActive || totalMonths > communicationalHistory.length
            ? initialValue.concat(
                effectiveCommunicationalHistory.concat(
                  calculateEffectivePoints(
                    employee.stats.interpersonal.number,
                    employee.stats.motivation.number
                  )
                )
              )
            : initialValue.concat(effectiveCommunicationalHistory);
        return communicationalData.map((m, i) =>
          acc[i] ? acc[i] + parseInt(m) : parseInt(m)
        );
      }, []);
    })
    .value();

  const filteredTeams = teamNames.filter(
    x => !!props.facilitatorReports.filteredTeams[x]
  );

  const data = {
    labels: generateMonths(totalMonths),
    datasets: filteredTeams.map((teamName, i) => {
      return {
        label: teamName,
        fill: true,
        backgroundColor: 'transparent',
        borderColor: props.reportTeamColors[i],
        data: employeeFunctionalByTeam[teamName],
      };
    }),
  };

  const data2 = {
    labels: generateMonths(totalMonths),
    datasets: filteredTeams.map((teamName, i) => {
      return {
        label: teamName,
        fill: true,
        backgroundColor: 'transparent',
        borderColor: props.reportTeamColors[i],
        data: employeeCommunicationalByTeam[teamName],
      };
    }),
  };

  const options = {
    maintainAspectRatio: false,
    legend: {
      display: true,
    },
    tooltips: {
      intersect: false,
    },
    hover: {
      intersect: true,
    },
    plugins: {
      filler: {
        propagate: false,
      },
    },
    scales: {
      xAxes: [
        {
          reverse: true,
          gridLines: {
            color: 'rgba(0,0,0,0.05)',
          },
        },
      ],
      yAxes: [
        {
          ticks: {
            stepSize: 100,
          },
          display: true,
          borderDash: [5, 5],
          gridLines: {
            color: 'rgba(0,0,0,0)',
            fontColor: '#fff',
          },
        },
      ],
    },
  };
  const rankedFunctionalSkills = Object.keys(employeeFunctionalByTeam).reduce(
    (acc, team) =>
      Object.assign({}, acc, {
        [team]: {
          rank: getScoreRank(employeeFunctionalByTeam, team),
          score:
            employeeFunctionalByTeam[team][
              employeeFunctionalByTeam[team].length - 1
            ],
        },
      }),
    {}
  );

  const rankedCommunicationalSkills = Object.keys(
    employeeCommunicationalByTeam
  ).reduce(
    (acc, team) =>
      Object.assign({}, acc, {
        [team]: {
          rank: getScoreRank(employeeCommunicationalByTeam, team),
          score:
            employeeCommunicationalByTeam[team][
              employeeCommunicationalByTeam[team].length - 1
            ],
        },
      }),
    {}
  );

  const tableRows =
    totalMonths > 0 ? (
      filteredTeams.map((teamName, i) => {
        return (
          <tr key={i}>
            <td>
              <FontAwesomeIcon
                icon={faCircle}
                className="mr-3"
                style={{ color: props.reportTeamColors[i] }}
              />
              {teamName}
            </td>
            <td className="text-right">
              {rankedFunctionalSkills[teamName].score}
            </td>
            <td
              className={`text-right ${
                ['1st', '2nd', '3rd'].includes(
                  rankedFunctionalSkills[teamName].rank
                )
                  ? 'text-success'
                  : 'text-dark'
              }`}
            >
              {rankedFunctionalSkills[teamName].rank}
            </td>
            <td className="text-right">
              {rankedCommunicationalSkills[teamName].score}
            </td>
            <td
              className={`text-right ${
                ['1st', '2nd', '3rd'].includes(
                  rankedCommunicationalSkills[teamName].rank
                )
                  ? 'text-success'
                  : 'text-dark'
              }`}
            >
              {rankedCommunicationalSkills[teamName].rank}
            </td>
          </tr>
        );
      })
    ) : (
      <tr />
    );

  return (
    <React.Fragment>
      <Row className="mb-4 pb-3">
        <Col md={6} style={{ height: '300px' }}>
          <h4>Functional Skills</h4>
          <Line data={data} options={options} />
        </Col>
        <Col md={6} style={{ height: '300px' }}>
          <h4>Communication Skills</h4>
          <Line data={data2} options={options} />
        </Col>
      </Row>
      {props.totalMonths > 0 ? (
        <Table striped>
          <thead>
            <tr>
              <th>Team</th>
              <th className="text-right" width="20%">
                Functional Skill
              </th>
              <th className="text-right" width="5%">
                Rank
              </th>
              <th className="text-right" width="20%">
                Communication Skill
              </th>
              <th className="text-right" width="5%">
                Rank
              </th>
            </tr>
          </thead>
          <tbody>{tableRows}</tbody>
        </Table>
      ) : (
        <h4>This data is not yet available.</h4>
      )}
    </React.Fragment>
  );
}

export default connect(state => ({
  reportTeamColors: state.theme.reportTeamColors,
  isMonthActive: state.facilitatorState.isMonthActive,
  facilitatorReports: state.facilitatorReports,
  totalMonths: state.facilitatorState.currentMonth,
}))(SkillGrowth);
